import { FC } from 'react'

import { Badge } from 'ds/components'

import { formatDateStringShort } from 'lib/utils/dateStringUtils'
import { formatCurrency } from 'lib/utils/formatCurrency'

type Props = {
    settledDate: string | null
    notificationSent: boolean | string
    amountPaid?: string
    amountRemaining?: string
    ecartLatestPaymentAttemptStatus?: string
}

export const NotificationBadge: FC<Props> = ({
    settledDate,
    amountRemaining,
    amountPaid,
    notificationSent,
    ecartLatestPaymentAttemptStatus,
}) => {
    if (settledDate)
        return (
            <Badge variant="success">Pagado {formatDateStringShort(settledDate)}</Badge>
        )
    if (ecartLatestPaymentAttemptStatus === 'declined')
        return <Badge variant="danger">Pago Fallido</Badge>
    if (Number(amountPaid))
        return (
            <Badge variant="warning">Por cobrar: {formatCurrency(amountRemaining)}</Badge>
        )

    if (notificationSent) return <Badge variant="primary">Enviado</Badge>
    return <Badge variant="neutral">No enviado</Badge>
}
