import { useContext, useMemo, useState } from 'react'
import { DueDateBadge } from 'lib/common/DueDateBadge'
import { PaidIcon } from './PaidIcon'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { formatDateShort } from 'lib/utils/formatDateShort'
import { InvoiceActionsButton } from '../InvoiceActionsButton'
import { CfdiBadge } from '../CfdiBadge'
import { NotificationBadge } from '../NotificationBadge'
import { Modal, Table } from 'ds/components'
import { BulkCreateCfdis } from './BulkCreateCfdis'
import { BulkRecordPayments } from './BulkRecordPayments'
import { RecordPaymentFormLoader } from '../RecordPaymentFormLoader'
import { castDecimal } from 'lib/utils/castDecimal'
import { CustomerAndOrderDetail } from '../CustomerAndOrderDetail'
import { SessionContext } from 'lib/hoc/withSession'
import { hasPermission } from 'lib/utils/hasPermission'
import { Permission } from 'lib/models/permission'
import { BulkTagModal } from '../BulkTagModal'
import { formatDateStringShort } from 'lib/utils/dateStringUtils'
import { SelectableColumn } from '../SelectableColumn'

export const InvoiceTable = ({
    invoices,
    isLoading,
    manualPagination,
    onDownload,
    onSort,
}) => {
    const { session } = useContext(SessionContext)
    const [showModal, setShowModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const relativeDate = localStorage.getItem('relativeDateFormat') || 'relative'
    const [relativeDateFormat, setRelativeDateFormat] = useState(relativeDate)

    const canEdit = hasPermission(session, Permission.WRITE_INVOICE)
    const canDelete = hasPermission(session, Permission.DELETE_INVOICE)
    const canCreatePayment = hasPermission(session, Permission.WRITE_PAYMENT)

    const batchConfigs = useMemo(() => {
        const configs = []
        const createConfig = (modal, text) =>
            configs.push({
                label: text,
                clearSelection: true,
                action: (selectedRows) => {
                    setShowModal(modal)
                    setSelectedRows(selectedRows)
                },
            })
        if (canEdit) {
            createConfig('cfdi', 'Generar CFDI(s)')
            createConfig('tag', 'Tag Cobros')
        }
        if (canCreatePayment) createConfig('payment', 'Registrar Pago(s)')
        return configs
    }, [canEdit, canCreatePayment])

    const columns = useMemo(() => {
        const cols = [
            {
                header: '',
                enableSorting: false,
                accessorKey: 'settled_date',
                cell: (data) => (
                    <PaidIcon
                        canCreatePayment={canCreatePayment}
                        invoice={data.row.original}
                        key={data.row.original.invoice_id}
                    />
                ),
            },
            { header: 'Folio', accessorKey: 'invoice_num' },
            {
                header: 'Cliente y Concepto',
                accessorKey: 'customer',
                cell: (data) => (
                    <CustomerAndOrderDetail
                        customer={data.getValue()}
                        order_detail={data.row.original.order_detail}
                    />
                ),
            },
            {
                header: 'Monto',
                accessorKey: 'amount_total',
                align: 'right',
                cell: (data) => (
                    <div className="right">{formatCurrency(data.getValue())}</div>
                ),
            },
            { header: 'Moneda', accessorKey: 'currency' },
            {
                header: 'Fecha',
                accessorKey: 'time_invoice',
                cell: (data) => formatDateShort(data.getValue()),
            },
            {
                header: () => (
                    <>
                        <div className="inline inline-sm">Vencimiento</div>
                        <div
                            className="pointer inline middle"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                const val =
                                    relativeDateFormat === 'relative'
                                        ? 'absolute'
                                        : 'relative'
                                localStorage.setItem('relativeDateFormat', val)
                                setRelativeDateFormat(val)
                            }}
                        >
                            <ion-icon name="calendar-outline" />
                        </div>
                    </>
                ),
                accessorKey: 'time_due',
                cell: (data) => (
                    <DueDateBadge
                        timeDue={data.getValue()}
                        settled={!!data.row.original.settled_date}
                        relative={relativeDateFormat === 'relative'}
                    />
                ),
            },
            {
                header: 'Estatus',
                accessorKey: 'notification_sent',
                enableSorting: false,
                cell: (data) => (
                    <NotificationBadge
                        ecartLatestPaymentAttemptStatus={
                            data.row.original.ecart_latest_payment_attempt_status
                        }
                        amountPaid={data.row.original.amount_paid}
                        amountRemaining={data.row.original.amount_remaining}
                        notificationSent={data.row.original.notification_sent}
                        settledDate={data.row.original.settled_date}
                    />
                ),
            },
            {
                header: 'CFDI',
                accessorKey: 'cfdi_i_payment_method',
                cell: (data) => <CfdiBadge invoice={data.row.original} />,
            },

            {
                header: '',
                enableSorting: false,
                accessorKey: 'invoice',
                cell: (data) => (
                    <div className="d-flex justify-content-end align-items-center">
                        {Boolean(data.row.original.subscription_id) && (
                            <div className="inline-sm">
                                <ion-icon name="repeat-outline"></ion-icon>
                            </div>
                        )}
                        <div>
                            <InvoiceActionsButton
                                canCreatePayment={canCreatePayment}
                                canDelete={canDelete}
                                canEdit={canEdit}
                                invoice={data.row.original}
                            />
                        </div>
                    </div>
                ),
            },
        ]
        if (canEdit || canCreatePayment) {
            cols.unshift(SelectableColumn)
        }
        return cols
    }, [relativeDateFormat, canDelete, canEdit, canCreatePayment])

    const rows = invoices.map((invoice) => {
        const amount_remaining = castDecimal(invoice.amount_total)
            .minus(castDecimal(invoice.amount_paid))
            .toNumber()
        return {
            select: '',
            amount_remaining,
            ...invoice,
            exportToExcel: {
                series: invoice.series,
                invoice_num: invoice.invoice_num,
                customer_tax_id: invoice.customer_tax_id,
                customer_legal_name: invoice.customer_legal_name,
                customer: invoice.customer,
                order_detail: invoice.order_detail,
                amount_subtotal: formatCurrency(invoice.amount_subtotal),
                amount_tax: formatCurrency(invoice.amount_tax),
                amount_withholding: formatCurrency(invoice.amount_withholding),
                amount_total: formatCurrency(invoice.amount_total),
                amount_remaining,
                amount_paid: formatCurrency(invoice.amount_paid),
                currency: invoice.currency,
                time_invoice: formatDateShort(invoice.time_invoice),
                time_due: formatDateShort(invoice.time_due),
                settled_date: formatDateStringShort(invoice.settled_date),
                cfdi_i_payment_method: invoice.cfdi_i_payment_method,
                has_cfdi_cancellation_pending: invoice.has_cfdi_cancellation_pending,
                notification_sent: Boolean(invoice.notification_sent),
            },
        }
    })

    const data = useMemo(() => rows, [rows])
    const selectedInvoices = selectedRows

    return (
        <div>
            <Table
                isLoading={isLoading}
                columns={columns}
                data={data}
                getRowLink={(row) => `/invoices/${row.invoice_id}`}
                clientSidePagination={!manualPagination}
                exportToExcelFilename={!onDownload && 'invoices'}
                manualPagination={manualPagination}
                onDownload={onDownload}
                onSort={onSort}
                batchConfigs={batchConfigs}
            />

            {showModal === 'cfdi' ? (
                <Modal title="Generar CFDIs" onClose={() => setShowModal(false)}>
                    <BulkCreateCfdis invoices={selectedInvoices} />
                </Modal>
            ) : showModal === 'payment' ? (
                <Modal title="Registrar Pagos" onClose={() => setShowModal(false)}>
                    {selectedInvoices.every(
                        (i) => i.customer_id === selectedInvoices[0].customer_id
                    ) ? (
                        <RecordPaymentFormLoader
                            customer_id={selectedInvoices[0].customer_id}
                            invoices={selectedInvoices}
                            onSuccess={() => setShowModal(false)}
                        />
                    ) : (
                        <BulkRecordPayments invoices={selectedInvoices} />
                    )}
                </Modal>
            ) : showModal === 'tag' ? (
                <BulkTagModal
                    onClose={() => setShowModal(false)}
                    invoices={selectedInvoices}
                />
            ) : null}
        </div>
    )
}
